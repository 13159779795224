import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Clients = () => {
  const [telegramId, setTelegramId] = useState(null);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const telegram = window.Telegram.WebApp;
    const initData = telegram.initDataUnsafe;
    const userId = initData.user?.id;

    if (userId) {
      setTelegramId(userId);
      fetchClients(userId);
    }
  }, []);

  const fetchClients = async (managerId) => {
    try {
      const response = await axios.get(`/api/clients/${managerId}`);
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients data', error);
    }
  };

  return (
    <div>
      <h1>Clients</h1>
      {telegramId ? (
        <div>
          <h2>Clients of Manager ID: {telegramId}</h2>
          {clients.length > 0 ? (
            <ul>
              {clients.map(client => (
                <li key={client.id}>{client.first_name} {client.last_name}</li>
              ))}
            </ul>
          ) : (
            <p>No clients found.</p>
          )}
        </div>
      ) : (
        <p>Loading Telegram data...</p>
      )}
    </div>
  );
};

export default Clients;
