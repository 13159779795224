import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Agents = () => {
  const [telegramId, setTelegramId] = useState(null);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    const telegram = window.Telegram.WebApp;
    const initData = telegram.initDataUnsafe;
    const userId = initData.user?.id;

    if (userId) {
      setTelegramId(userId);
      fetchAgents(userId);
    }
  }, []);

  const fetchAgents = async (managerId) => {
    try {
      const response = await axios.get(`/api/agents/${managerId}`);
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching agents data', error);
    }
  };

  return (
    <div>
      <h1>Agents</h1>
      {telegramId ? (
        <div>
          <h2>Agents of Manager ID: {telegramId}</h2>
          {agents.length > 0 ? (
            <ul>
              {agents.map(agent => (
                <li key={agent.id}>{agent.first_name} {agent.last_name}</li>
              ))}
            </ul>
          ) : (
            <p>No agents found.</p>
          )}
        </div>
      ) : (
        <p>Loading Telegram data...</p>
      )}
    </div>
  );
};

export default Agents;
