import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Home = () => {
  const [telegramId, setTelegramId] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const telegram = window.Telegram.WebApp;
    const initData = telegram.initDataUnsafe;
    const userId = initData.user?.id;

    if (userId) {
      setTelegramId(userId);
      fetchUserData(userId);
    }
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.get(`/api/user/${userId}`);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data', error);
    }
  };

  return (
    <div>
      <h1>Home</h1>
      {telegramId ? (
        <div>
          <h2>User ID: {telegramId}</h2>
          {userData ? (
            <div>
              <h3>User Data:</h3>
              <pre>{JSON.stringify(userData, null, 2)}</pre>
            </div>
          ) : (
            <p>Loading user data...</p>
          )}
        </div>
      ) : (
        <p>Loading Telegram data...</p>
      )}
    </div>
  );
};

export default Home;
